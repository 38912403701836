import { render, staticRenderFns } from "./Partners.vue?vue&type=template&id=f1a58a8c&scoped=true&ref=adminUser&"
import script from "./Partners.vue?vue&type=script&lang=js&"
export * from "./Partners.vue?vue&type=script&lang=js&"
import style0 from "./Partners.vue?vue&type=style&index=0&id=f1a58a8c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1a58a8c",
  null
  
)

export default component.exports